import React, { useState, useEffect } from "react";
import Layout from "../../components/chat-channel/Layout";
import Container from "../../components/chat-channel/Container";
import RegisterSection from "../../components/chat-channel/ChannelHeader";
import "react-web-tabs/dist/react-web-tabs.css";
import TitleAndMetaTags from "../../components/common/TitleAndHeader";
import { RequestForm } from "../../components/form";
import { useMedia } from "use-media";

const w_img = require("../../assets/img/home_page_assist/webinar_img.png");
const webinar_home = require("../../assets/img/home_page_assist/home_webinar.png");

export default function chatbotWebinar() {
  return (
    <>
      <TitleAndMetaTags
        title="Free Bamboo HR Chatbot Integration | How to build Bamboo HR AI chatbot and tasks using Conversational AI Platform"
        description="Learn how to build Bamboo HR chatbot in Slack and Teams using Conversational AI Platform Create, update, and modify Bamboo HR tickets from chatbot and automate your workplace support"
        keywords={[
          "HR chatbot, Automation, AI Chatbot",
          "workativ free trial",
          "helpdesk chatbot free trial",
          "automation",
        ]}
        ogImage={webinar_home}
      />
      <section className="webinar-utube-container">
        <Container>
          <Layout backgroundColor={"landing_bg"} logoFor="ASSISTANT">
            <RegisterSection
              rightImage={webinar_home}
              backgroundColor={"landing_bg"}
              altImage={"BambooHR chatbot with automation"}
              iD={"chatbot"}
            >
              <RegisterSection.FormWithContent>
                <RegisterSection.LogoImage
                  additionalClassLogoImage={"display-none"}
                ></RegisterSection.LogoImage>
                <RegisterSection.MainHeader>
                  Insightful Webinars From <br /> Leading Experts
                </RegisterSection.MainHeader>
                <RegisterSection.SubHeader
                  additionalClassSubHeader={"display-none"}
                ></RegisterSection.SubHeader>
              </RegisterSection.FormWithContent>
            </RegisterSection>
            <WebinarVideosGrid />
            <RequestForm isFooterForm={true} />
          </Layout>
        </Container>
      </section>
    </>
  );
}

export const WebinarVideosGrid = () => {
  const isSmall = useMedia({ maxWidth: "520px" });
  const boxesPerRow = isSmall ? 2 : 3;
  const totalRows = Math.ceil(videoData.length / boxesPerRow);
  const [visibleRows, setVisibleRows] = useState(3);
  const handleShowMore = () => {
    setVisibleRows(totalRows);
  };

  return (
    <section className="webinar-videos-main-container w-100 float-left">
      <div className="container">
        <div className="webinar-vd-flex">
          {videoData.slice(0, visibleRows * boxesPerRow).map((data) => (
            <div
              className="webinar-video-box"
              style={{ width: `calc(90% / ${boxesPerRow})` }}
            >
              {" "}
              {data.upcoming ? (
                <h4 className="font-section-webinar-upcoming mb-0 webinar-upcoming">
                  UPCOMING WEBINAR
                </h4>
              ) : null}
              <div className="webinar-top-video-box">
                <img src={data.image} alt={data.header} />
              </div>
              <div className="webinar-top-bottom-box">
                <h2 className="font-section-normal-text-testimonials-medium line-height-15">
                  {data.header}
                </h2>
                {data.registernow ? (
                  <div className="date-register-webinar">
                    <p className="font-section-webinar-date mb-0 pb-0">
                      By Workativ | {data.date}
                    </p>
                    <button
                      className="webinar-register-now-button font-section-webinar-register"
                      style={{ outline: "none" }}
                    >
                      Register Now!
                    </button>
                  </div>
                ) : (
                  <p className="font-section-webinar-date margin-top-17 mb-0 pb-0">
                    By Workativ | {data.date}
                  </p>
                )}
              </div>
            </div>
          ))}
        </div>
        {videoData.length > visibleRows * boxesPerRow ? (
          <button
            onClick={handleShowMore}
            className="show-more-webinar font-name-smaller-text-medium"
          >
            Show More
          </button>
        ) : null}
      </div>
    </section>
  );
};

export const videoData = [
  {
    image: w_img,
    header: "Impact of Generative AI on Enterprises",
    date: "04 May 2023",
    registernow: true,
    upcoming: true,
  },
  {
    image: w_img,
    header: "How to Handover the Chat to a Live Agent",
    date: "03 May 2023",
  },
  {
    image: w_img,
    header: "IT Support Automation: Increase ROI & Reduce costs",
    date: "20 April 2023",
  },
  {
    image: w_img,
    header: "IT Support Automation: Increase ROI & Reduce costs",
    date: "20 April 2023",
  },
  {
    image: w_img,
    header: "IT Support Automation: Increase ROI & Reduce costs",
    date: "20 April 2023",
  },
  {
    image: w_img,
    header: "IT Support Automation: Increase ROI & Reduce costs",
    date: "20 April 2023",
  },
  {
    image: w_img,
    header: "IT Support Automation: Increase ROI & Reduce costs",
    date: "20 April 2023",
  },
  {
    image: w_img,
    header: "IT Support Automation: Increase ROI & Reduce costs",
    date: "20 April 2023",
  },
  {
    image: w_img,
    header: "IT Support Automation: Increase ROI & Reduce costs",
    date: "20 April 2023",
  },

  {
    image: w_img,
    header: "IT Support Automation: Increase ROI & Reduce costs",
    date: "20 April 2023",
  },
  {
    image: w_img,
    header: "IT Support Automation: Increase ROI & Reduce costs",
    date: "20 April 2023",
  },
  {
    image: w_img,
    header: "IT Support Automation: Increase ROI & Reduce costs",
    date: "20 April 2023",
  },
];
